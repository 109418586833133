export default class Popup {
    constructor() {
        this.lang = window.CookieConsent.language;
    }
    create() {
        const text = {
            'en': 'We use cookies on our website. Some of them are essential, while others help us to improve this website and your experience.',
            'de': 'Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung yu verbessern.'
        };
        const consent_essential = {
            'en': 'Essential',
            'de': 'Essenziell'
        }
        const consent_marketing = {
            'en': 'Marketing',
            'de': 'Marketing'
        }
        const acceptText = {
            'en': 'Accept all',
            'de': 'Alle akzeptieren'
        }
        const saveText = {
            'en': 'Save',
            'de': 'Speichern'
        }
        const htmlSwitch = `
        <div id="consent_popup" class="gdpr-modal">
        <div class="gdpr-modal-content" align="center">
              <h2>Cookies-instellingen</h2>
              <p>U kunt beslissen aan welke cookies u toestemming wilt geven. </br>
              U kunt deze instellingen wijzigen wanneer u maar wilt. Maar als gevolg hiervan kan het gebeuren dat sommige cookiediensten niet meer beschikbaar zijn. Meer informatie over het verwijderen van cookie-bestanden vindt u in het helpgedeelte van uw internetbrowser.</p>
              <p><a href="https://www.wi-bo.nl/nl/Privacy">Meer informatie over cookies die we gebruiken.</a></p>    
              
              <table id="gdpr" align="center">
                <tr>
                    <td class="align-middle">
                      <label class="gdpr-switch">
                        <input type="checkbox" value="#0" checked="checked" disabled>
                        <span class="gdpr-slider round"></span>
                      </label>
                    </td>
                    <td>
                      <p><strong>Technische en tracking cookies</strong></br>
                      Dit zijn cookies die nodig zijn voor fundamentele functies van websites. Wij gebruiken geen andere cookies die alle persoonlijke gegevens verwerken en niet noodzakelijk zijn voor de goede werking van onze websites.</p>
                    </td>
                </tr>
                <tr>
                <td>
                  <label class="gdpr-switch">
                  <input type="checkbox" id="consent_marketing" class="gdprCheckboxes">
                  <span class="gdpr-slider round"></span>
                  </label>
                </td>
                <td>
                  <p><strong>Marketing</strong></br>
                  We gebruiken digitale advertentiecookies van <a href="https://policies.google.com/technologies/ads">Google AdWords</a>, <a href="https://policies.google.com/technologies/ads">DoubleClick</a> en <a href="https://policies.google.com/technologies/ads">DoubleClick</a>. U kunt meer informatie krijgen op de websites van elke provider.</p>
                </td>
              </table>
              <br/>
            <div class="buttons" align="center">
                <button id="saveBtn" class="gdpr-btn">${saveText[this.lang]}</button>
                <button id="acceptAllBtn" class="gdpr-btn">${acceptText[this.lang]}</button>
            </div>
        </div>
        </div>
        `;

        const htmlCheckbox = `
            <div id="consent_popup" style="display: none;">
                <p>${text[this.lang]}</p>
                <span><input type="checkbox" id="consent_essential" name="consent_essential" checked="checked" disabled="disabled" value="" /><label for="consent_essential">${consent_essential[this.lang]}</label></span>
                <span><input type="checkbox" id="consent_marketing" name="consent_marketing" value="off" /><label for="consent_marketing">${consent_marketing[this.lang]}</label></span>
                <div class="buttons">
                    <button id="acceptAllBtn" class="btn btn-dark_blue">${acceptText[this.lang]}</button>
                    <button id="saveBtn" class="btn btn-grey">${saveText[this.lang]}</button>
                </div>
            </div>
        `;

        if(window.CookieConsent.popupMode === 'checkbox') {
            document.body.innerHTML+= htmlCheckbox;
        }else {
            document.body.innerHTML+= htmlSwitch;
        }

        this.popup = document.getElementById('consent_popup');

        this.saveBtn = document.getElementById('saveBtn');
        this.acceptAllBtn = document.getElementById('acceptAllBtn');
        this.marketingCheckbox = document.getElementById('consent_marketing');

        if(this.saveBtn) {
            this.saveBtn.addEventListener('click', (event) => {
                window.CookieConsent.save();
            });
        }
        if(this.acceptAllBtn) {
            this.acceptAllBtn.addEventListener('click', (event) => {
                window.CookieConsent.acceptAll();
            });
        }

    }
    checkMarketingCheckbox() {
        this.marketingCheckbox.checked = true;
    }
    show() {
        if(window.CookieConsent.hasConsented() === 'marketing'){
            this.marketingCheckbox.checked = true;
        }
        this.popup.style.display = "block";
    }

    hide() {
        this.popup.style.display = "none";
    }
}