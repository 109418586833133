import Cookie from './cookie.js';
import Popup from './popup.js';

export default  class CookieConsent {
    constructor() {
        this.language = document.documentElement.lang || 'de';
    }
    init() {
        this.Popup = new Popup();
        this.Popup.create();

        if(this.hasConsented() === 'marketing'){
            this.Popup.checkMarketingCheckbox();
        }
        this.createChangeConsentBtn();

        console.log('hasConsented=' + this.hasConsented());

        if (this.hasConsented() == null) {
            this.Popup.show();
        }
        else if(this.hasConsented() === 'marketing') {
            this.fireTags();
        }
    }

    acceptAll() {
        this.saveConsent('marketing');
        this.fireTags();
        this.Popup.hide();
    }

    save() {
        if (this.Popup.marketingCheckbox && this.Popup.marketingCheckbox.checked) {
            this.fireTags();
            this.saveConsent('marketing');
        } else {
            this.saveConsent('no');
        }
        this.Popup.hide();
    }
    saveConsent(consent) {
        Cookie.createCookie('consent', consent, 365);
        console.log('hasConsented=' + this.hasConsented());
    }

    fireTags() {
        var scripts = document.querySelectorAll("script[type='text/plain'][data-consent-category='marketing']");
        scripts.forEach(function(element, index) {
            eval(element.textContent);
        });
    }

    createChangeConsentBtn() {
        var changeConsent = {
            'en': 'Change consent',
            'de': 'Zustimmung ändern'
        }
        this.changeConsentBtn = document.getElementById('changeConsentBtn');
        if(this.changeConsentBtn) {
            this.changeConsentBtn.innerText = changeConsent[this.language];
            this.changeConsentBtn.addEventListener('click', (event) => {
                this.Popup.show();
            })
        }
    }

    hasConsented() {
        return Cookie.readCookie('consent');
    }
}